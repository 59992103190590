<template>
  <div class="-m-2 p-2 rounded-xl bg-gradient-to-t from-red-100/10 to-red-100/90 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
    <div class="relative aspect-video overflow-hidden rounded-md shadow-2xl ring-1 ring-gray-900/10">
      <!-- A loop is required for all images to be processed by Nuxt Image -->
      <template
        v-for="(image, index) in images"
        :key="image.link"
      >
        <transition name="blur">
          <nuxt-img
            v-show="currImageIndex === index"
            :loading="currImageIndex !== 0 ? 'lazy' : 'eager'"
            :src="image.link"
            :alt="image.title"
            sizes="xs:320px sm:640px md:768px lg:1280px"
            class="absolute w-full"
          />
        </transition>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ImageSliderProps } from 'types/image'

const { images, interval } = defineProps<ImageSliderProps>()

const currImageIndex = ref<number>(0)

let timeoutId: number = 0

function startTimer () {
  const totalImages = images.length
  const lastIndex = totalImages - 1
  const nextIndex = currImageIndex.value + 1

  currImageIndex.value = nextIndex <= lastIndex ? nextIndex : 0

  window.clearTimeout(timeoutId)

  timeoutId = window.setTimeout(startTimer, interval)
}

onMounted(() => {
  timeoutId = window.setTimeout(startTimer, interval)
})

onBeforeUnmount(() => {
  window.clearTimeout(timeoutId)
})
</script>
