import revive_payload_client_hiZPbHUB25 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RUJ3hsj9tN from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NPh4BHerYo from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_pDxoqT8mRr from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.26.0_vite@6.0.7_@types+node@22.9.0_jiti@2.4.2__lm3prj54jbewgvap6aadtp5b7u/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_TXpfdzfaFY from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hOVU1sNM5n from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_0pPeP69Gwc from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ZgukVfGAu2 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/bgrand/Documents/Projects/live-display/public/.nuxt/components.plugin.mjs";
import prefetch_client_pPxbTM6kmu from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import firebase_MrRCoKgj2U from "/home/bgrand/Documents/Projects/live-display/shared/plugins/firebase.ts";
import airbrake_H5MbEqRsNX from "/home/bgrand/Documents/Projects/live-display/shared/plugins/airbrake.ts";
import aos_u8RxmzeSAw from "/home/bgrand/Documents/Projects/live-display/public/plugins/aos.ts";
export default [
  revive_payload_client_hiZPbHUB25,
  unhead_RUJ3hsj9tN,
  router_NPh4BHerYo,
  _0_siteConfig_pDxoqT8mRr,
  payload_client_TXpfdzfaFY,
  navigation_repaint_client_hOVU1sNM5n,
  check_outdated_build_client_0pPeP69Gwc,
  chunk_reload_client_ZgukVfGAu2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pPxbTM6kmu,
  firebase_MrRCoKgj2U,
  airbrake_H5MbEqRsNX,
  aos_u8RxmzeSAw
]