
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as conditions_45generales_45utilisationuj0Bl2KKvZMeta } from "/home/bgrand/Documents/Projects/live-display/public/pages/conditions-generales-utilisation.vue?macro=true";
import { default as conditions_45generales_45venteJiP0YiG0e8Meta } from "/home/bgrand/Documents/Projects/live-display/public/pages/conditions-generales-vente.vue?macro=true";
import { default as contactt9QKS3BheSMeta } from "/home/bgrand/Documents/Projects/live-display/public/pages/contact.vue?macro=true";
import { default as indexrGTiDm5XQwMeta } from "/home/bgrand/Documents/Projects/live-display/public/pages/index.vue?macro=true";
import { default as politique_45confidentialiteLAo9gTnW6ZMeta } from "/home/bgrand/Documents/Projects/live-display/public/pages/politique-confidentialite.vue?macro=true";
export default [
  {
    name: "conditions-generales-utilisation",
    path: "/conditions-generales-utilisation",
    component: () => import("/home/bgrand/Documents/Projects/live-display/public/pages/conditions-generales-utilisation.vue")
  },
  {
    name: "conditions-generales-vente",
    path: "/conditions-generales-vente",
    component: () => import("/home/bgrand/Documents/Projects/live-display/public/pages/conditions-generales-vente.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/bgrand/Documents/Projects/live-display/public/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/bgrand/Documents/Projects/live-display/public/pages/index.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    component: () => import("/home/bgrand/Documents/Projects/live-display/public/pages/politique-confidentialite.vue")
  }
]