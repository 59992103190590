<template>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <section-title
      v-bind="titleProps"
      class="mx-auto max-w-2xl lg:text-center"
    />

    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
        <div
          v-for="feature in features"
          :key="feature.title"
          class="relative pl-16"
        >
          <dt class="text-base/7 font-semibold text-gray-900">
            <div class="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-red-600">
              <icon
                :name="feature.icon"
                aria-hidden="true"
                class="size-6 text-white"
              />
            </div>

            {{ feature.title }}
          </dt>

          <dd class="mt-2 text-base/7 text-gray-600">
            {{ feature.description }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SectionTitleProps } from 'types/section'

const titleProps: SectionTitleProps = {
  kicker: 'Fonctionnalités',
  title: 'Créez un diaporama attrayant',
  description: `${appName} est simple d'utilisation et rapide à prendre en main. Sans connaissance technique, vous construisez votre diaporama avec l'éditeur de contenu. Vous créez ainsi le diaporama adapté à vos besoins et à vos écrans.`
}

const features = [
  {
    icon: 'ph:note-pencil-duotone',
    title: 'Construisez un diaporama facilement',
    description: 'Vous construisez un diaporama en toute simplicité avec vos photos et vidéos, des titres accrocheurs et des infos utiles pour vos clients.',
  },
  {
    icon: 'ph:calendar-dots-duotone',
    title: 'Planifiez l\'affichage des infos',
    description: 'Vous pouvez planifier en avance l\'affichage des infos à un horaire définis ou pour un événement spécifique, comme pour le Black Friday.',
  },
  {
    icon: 'ph:layout-duotone',
    title: 'Utilisez un modèle pour aller plus vite',
    description: 'Vous avez à disposition des modèles conçus pour votre secteur d\'activité. Vous gagnez alors du temps pour créer votre diaporama.',
  },
  {
    icon: 'ph:users-duotone',
    title: 'Travaillez à plusieurs personnes',
    description: `Vous êtes libre d\'ajouter d\'autres personnes sur votre compte ${appName}. Vous pouvez donc travailler à plusieurs et vous répartir les tâches.`,
  }
]
</script>
