<template>
  <div class="overflow-hidden mx-auto max-w-7xl px-6 lg:px-8">
    <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
      <div class="lg:pr-4">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
          <section-title v-bind="titleProps" />

          <dl class="mt-10 max-w-xl space-y-8 text-base/7 lg:max-w-none">
            <div
              v-for="step in steps"
              :key="step.title"
              class="relative pl-9"
            >
              <dt class="inline font-semibold text-gray-900">
                <icon
                  :name="step.icon"
                  aria-hidden="true"
                  class="absolute left-1 top-1 size-5 text-red-600"
                />

                {{ step.title }}
              </dt>

              {{ ' ' }}

              <dd class="inline text-gray-600">
                {{ step.description }}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div>
        <div class="relative isolate overflow-hidden bg-red-600 px-6 pt-8 sm:mx-auto sm:max-w-2xl rounded-xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
          <div
            aria-hidden="true"
            class="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-red-100 opacity-20 ring-1 ring-inset ring-white"
          />

          <div class="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
            <nuxt-img
              :alt="'Écran ' + appName"
              loading="lazy"
              src="/images/steps.webp"
              sizes="xs:640px sm:768px md:1024px"
              class="-mb-12 w-[35rem] sm:w-[44rem] max-w-none rounded-tl-md sm:rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
            />
          </div>

          <div
            aria-hidden="true"
            class="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SectionTitleProps } from 'types/section'

const titleProps: SectionTitleProps = {
  kicker: 'Fonctionnement',
  title: 'Animez vos écrans',
  description: `${appName} est conçu pour vous et votre entreprise. C'est votre nouvel outil de communication. En seulement 3 étapes, vous affichez votre diaporama sur vos écrans.`
}

const steps = [
  {
    icon: 'ph:number-one-duotone',
    title: 'Créez votre diaporama',
    description: 'en toute simplicité et sans connaissance technique avec l\'éditeur de contenu. En quelques clics, vous construisez un diaporama captivant.'
  },
  {
    icon: 'ph:number-two-duotone',
    title: 'Liez un ou plusieurs écrans',
    description: `à ${appName} avec un simple code. Vous pouvez utiliser un boîter ${appName}, une télévision avec navigateur web ou un ordinateur.`
  },
  {
    icon: 'ph:number-three-duotone',
    title: 'Affichez votre diaporama',
    description: 'sur vos écrans en un clic. Les écrans sont mis à jour automatiquement et instantanément.'
  }
]
</script>
