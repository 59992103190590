import routerOptions0 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.1_@electric-sql+pglite@0.2.13_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_@e_mta3ebz3t3npw3lygz3elieocq/node_modules/nuxt/dist/pages/runtime/router.options.js";
const configRouterOptions = {
  strict: true,
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = false
export default {
...configRouterOptions,
...routerOptions0,
}